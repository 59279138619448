<script>

export default {
    name: "Blog",
    components: {

    },
    props: ['templateMode'],
     created(){
         this.$emit('headerState', 1);
    },
    methods: {
        

    }
};
</script>

<style lang="scss">
    @import "blog.scss";
</style>

<template src="./blog.html"></template>